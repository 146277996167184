import axios from "axios"
function AxiosRequest(url, params, callback) {
  axios
    .post(
      url,
      JSON.stringify({
        service_request: {
          params,
          request_info: {
            source_type: "android",
          },
        },
        version: "1.0",
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(res => {
      // console.log("get response => ", res.data);
      callback(res.data)
    })
}

export default AxiosRequest
